import {numWord, priceToNumber, numberToPrice} from '../functions/num-word';

class CartClass {
    #debug = true;
    #cartStorage = window.localStorage;
    #cart_key = 'cart';
    #cart_key_last_updated = 'cart_lu';
    #prefix_key = "c_";
    cached_date_for_box = 600;//is seconds

    log(msg) {
        if (this.#debug) console.log(msg);
    }

    warn(msg) {
        if (this.#debug) console.warn(msg);
    }

    init() {
        let $this = this;

        $this.renderCartBox();
        $this.markInCart();
        if ($this.needUpdate()) {
            setTimeout(() => {
                $this.update()
            }, 1000);
        }

        document.addEventListener('click', function (e) {
            if (e.target.closest(".pr-obj .add_to_cart_from_catalog")) { //standart
                let prObj = e.target.closest(".pr-obj");
                let btn = e.target.closest(".add_to_cart_from_catalog");
                let data = Object.assign({}, btn.dataset);
                data.quantity = 1

                if (btn.classList.contains("in_cart_already")) {
                    window.location.assign(window.App.page.cart);
                    return;
                }

                if (typeof data.product == "undefined" || !data.product)
                    return;
                data.id = data.product;
                delete data.product;
                /*if ($(this).closest(".product").hasClass("product_with_count") && $(this).closest(".product").find("input[name=number]").length) {
                    var quantity_val = Number($(this).closest(".product").find("input[name=number]").val());
                    if (quantity_val) {
                        quantity = quantity_val;
                    }
                }*/

                if (prObj.querySelector(".pr-obj-name")) {
                    data.name = prObj.querySelector(".pr-obj-name").innerText.trim();
                }
                if (prObj.querySelector(".pr-obj-price")) {
                    data.price = $this.priceToNumberWR(prObj.querySelector(".pr-obj-price").innerText.trim());
                }

                $this.add([data]);
                $this.renderInCart(btn);
            } else if (e.target.closest(".obj-cart-panel .obj-cart-panel-item-remove")) { //remove from cart-panel
                let btn = e.target.closest(".obj-cart-panel-item-remove");
                let data = btn.dataset;

                if (typeof data.product == "undefined" || !data.product)
                    return;

                $this.remove(data.product);
                btn.closest('.basketHover__card').remove();

                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                return false;
            } else if (e.target.closest(".product_card_in_cart")) { //add to cart from product page
                let btn = e.target.closest(".product_card_in_cart");
                if (window.product_params && window.product_params.marketing && window.product_params.marketing.price) {
                    let to_cart = [];
                    window.product_params.marketing.quantity = 1;
                    to_cart.push(window.product_params.marketing);

                    document.querySelectorAll(".pr-obj-additional_input:checked").forEach(function (el) {
                        let prObj = el.closest(".pr-obj");
                        let product = {
                            id: el.value,
                            quantity: 1
                        }
                        if (prObj.querySelector(".pr-obj-name")) {
                            product.name = prObj.querySelector(".pr-obj-name").innerText.trim();
                        }
                        if (prObj.querySelector(".pr-obj-price")) {
                            let copy_price = prObj.querySelector(".pr-obj-price").cloneNode(true);
                            if (copy_price.querySelector("span")) {
                                copy_price.querySelector("span").remove();
                            }
                            product.price = $this.priceToNumberWR(copy_price.innerText.trim());
                        }
                        to_cart.push(product);
                    })
                    $this.add(to_cart);
                }
            } else if (e.target.closest(".js-add_tile")) { //add tile
                const elem = e.target.closest(".js-add_tile");
                const elem_card_tile = elem.closest(".catalogCollectionCardProduct");
                if (elem_card_tile && elem_card_tile.dataset.id) {
                    if (elem.classList.contains("btnStnd--catalogProduct")) {
                        const quality_obj = elem_card_tile.querySelector(".quantity .quantity__text");
                        const quality_value = (quality_obj && quality_obj.dataset.value) || 1;
                        const quality_text = quality_value + " " + (quality_obj && quality_obj.querySelector("div").innerHTML);
                        elem.classList.remove("btnStnd--catalogProduct");
                        elem.dataset.quality = quality_value;
                        elem.classList.add("btnStnd--catalogProduct2");
                        elem.innerHTML = '<i class="icon-free catalogCollectionCardProduct__btn2Icon"></i>В&nbsp;корзине <span>' + quality_text + '</span>';

                        $this.add([{
                            id: elem_card_tile.dataset.id,
                            quantity: quality_value
                        }]);

                        const popup = document.querySelector(".catalogCollectionBanner");
                        if (popup) {
                            let count = 0;
                            let total = 0;
                            document.querySelectorAll(".btnStnd--catalogProduct2").forEach(function (el) {
                                count++;
                                const quantity_text = el.closest(".catalogCollectionCardProduct")?.querySelector(".quantity .quantity__text");
                                if(quantity_text) {
                                    total += +quantity_text.dataset.price_per_box * (+el.dataset.quality);
                                }
                            })
                            if(count) {
                                popup.querySelector(".catalogCollectionBanner__text span").textContent = count + ' ' + numWord(count, ["позиция", "позиции", "позиций"]);
                                popup.querySelector(".catalogCollectionBanner__price span").textContent = "";
                                if(total) {
                                    popup.querySelector(".catalogCollectionBanner__price span").textContent = numberToPrice(total, true);
                                }
                                popup.classList.remove("catalogCollectionBanner--hidden")
                                setTimeout(() => {
                                    popup.classList.add("catalogCollectionBanner--hidden")
                                }, 5000)
                            }
                        }
                    } else {
                        elem.innerHTML = "В корзину";
                        elem.classList.add("btnStnd--catalogProduct")
                        elem.classList.remove("btnStnd--catalogProduct2")
                        $this.remove(elem_card_tile.dataset.id);
                    }
                }
            }
        })

        window.addEventListener('storage', event => {
            if (event.key !== $this.#cart_key_last_updated)
                return;
            $this.renderCartBox();
        });
    }

    priceToNumberWR(value) {
        return Math.round(priceToNumber(value));
    }

    add(products) {
        this.log("add");
        let cart = this.getStorage();
        let count = 0;
        let need_update = false;
        for (let product of products) {
            count++;
            let product_id = product.id;
            if (typeof cart[this.#prefix_key + product_id] !== "undefined") {
                cart[this.#prefix_key + product_id]['quantity'] = parseFloat(cart[this.#prefix_key + product_id]['quantity']) + parseFloat(product.quantity);
            } else {
                cart[this.#prefix_key + product_id] = product;
                cart[this.#prefix_key + product_id]['quantity'] = parseFloat(cart[this.#prefix_key + product_id]['quantity']);
                /*if (cart[product_id].hasOwnProperty('brand'))
                    delete cart[product_id]["brand"];
                if (cart[product_id].hasOwnProperty('category'))
                    delete cart[product_id]["category"];*/
            }
            if (typeof cart[this.#prefix_key + product_id]["url"] === "undefined") {
                need_update = true;
            }
        }

        window.MarketingApp.marketing_addToCart(products);

        this.renderCartBoxCount(Object.keys(cart).length);
        this.setStorage(cart);
        if (need_update)
            this.update();
        else {
            this.touchLastUpdated();
        }
        //sync with db
        if (window.App.auth) {
            this.sync();
        }
    }

    remove(product_id, silent) {
        let $this = this;
        this.log("remove");
        let cart = this.getStorage();
        if (typeof silent === "undefined")
            silent = false;
        if (typeof cart[$this.#prefix_key + product_id] !== "undefined") {
            let btns = document.querySelectorAll(".add_to_cart_from_catalog.in_cart_already[data-product='" + product_id + "']");
            if (btns.length) {
                btns.forEach(function (btn) {
                    $this.renderOutCart(btn);
                })
            }
            if (!silent) {
                window.MarketingApp.marketing_removeFromCart([cart[$this.#prefix_key + product_id]]);
            }
            delete cart[$this.#prefix_key + product_id];
        }

        this.setStorage(cart);
        this.renderCartBox();

        //sync with db
        if (window.App.auth) {
            this.sync();
        }
    }

    changeQuantity(product_id, new_quantity) {
        this.log("Cart changeQuantity");
        let cart = this.getStorage();
        if (typeof cart[this.#prefix_key + product_id] !== "undefined") {
            cart[this.#prefix_key + product_id].quantity = new_quantity;
            this.setStorage(cart);
            this.renderCartBox();
            this.touchLastUpdated();
            //sync with db
            if (window.App.auth) {
                this.sync();
            }
        }
    }

    sync() {
        let $this = this;
        $this.log("sync");
    }

    update() {
        let $this = this;
        $this.log("update");

        let cart = Object.keys($this.getStorage());
        if (cart && cart.length) {
            for (let key in cart) {
                cart[key] = cart[key].replace($this.#prefix_key, "");
            }
            fetch(window.App.ajax.cart.get, {
                method: 'POST',
                body: JSON.stringify({
                    "ids": cart
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                }
                ,
            })
                .then((response) => {
                    // Проверяем успешность запроса и выкидываем ошибку
                    if (!response.ok) {
                        $this.warn(response)
                        throw new Error('Error occurred!')
                    }

                    return response.json()
                })
                .then((data) => {
                        let cart = $this.getStorage();
                        $this.log(data);
                        if (data && data.products) {
                            for (let item of data.products) {
                                if (typeof cart[$this.#prefix_key + item.id] !== "undefined") {
                                    cart[$this.#prefix_key + item.id] = Object.assign(cart[$this.#prefix_key + item.id], item);
                                }
                            }
                        }
                        $this.setStorage(cart);
                        $this.setLastUpdated();
                        $this.renderCartBox();
                    }
                )
                .catch((err) => {
                    $this.warn(err)
                });
        }
    }

    renderCartBox() {
        let $this = this;
        $this.log("renderCartBox");
        let count = 0;
        let total = 0;
        let cart = $this.getStorage();

        document.querySelectorAll(".obj-cart-panel-hover .obj-cart-panel-list > *:not(.basketHover__card--template)").forEach(function (el) {
            el.remove();
        })

        if (cart) {
            for (let key in cart) {
                let item = cart[key];
                if (item["is_visible"]) {
                    count++;
                    total += Number(item["price"]) * Number(item["quantity"]);

                    let newItem = document.querySelector(".obj-cart-panel-hover .obj-cart-panel-list .basketHover__card--template").cloneNode(true);

                    newItem.classList.remove("basketHover__card--template");
                    newItem.querySelector(".basketHover__imgWrap").setAttribute("href", item["url"]);
                    newItem.querySelector(".basketHover__imgWrap img").setAttribute("data-src", item["thumb"]);
                    newItem.querySelector(".basketHover__imgWrap img").classList.add("lazyload");
                    newItem.querySelector(".basketHover__infoAttr").innerText = "Код:" + item["code"];
                    newItem.querySelector(".basketHover__infoName").setAttribute("href", item["url"]);
                    newItem.querySelector(".basketHover__infoName").innerText = item["name"];
                    newItem.querySelector("button.obj-cart-panel-item-remove").setAttribute("data-product", item["id"]);

                    document.querySelector(".obj-cart-panel-hover .obj-cart-panel-list").append(newItem);
                }
            }
        }
        document.querySelector(".obj-cart-panel-hover .obj-cart-panel-total").innerText = numberToPrice(total,true);

        if (!count) {
            document.querySelectorAll(".obj-cart-panel-hover > *:not(.obj-cart-panel-empty)").forEach(function (el) {
                el.style.display = 'none';
            })
            if (!document.querySelector(".obj-cart-panel-hover .obj-cart-panel-empty")) {
                let newElem = document.createElement("div");
                newElem.classList.add("obj-cart-panel-empty");
                newElem.innerHTML = "Ваша корзина пуста<br/>Вы еще не выбрали ни одного товара";
                document.querySelector(".obj-cart-panel-hover").append(newElem);
            }
        } else {
            document.querySelector(".obj-cart-panel-hover .obj-cart-panel-list").style.display = 'block';
            document.querySelector(".obj-cart-panel-hover .basketHover__wrap").style.display = 'flex';

            let basket_cart_empty = document.querySelector(".obj-cart-panel-hover .obj-cart-panel-empty")
            if (basket_cart_empty) {
                basket_cart_empty.remove();
            }
        }

        document.querySelector(".obj-cart-panel-hover").style.display = 'block';
        $this.renderCartBoxCount(count);
    }

    renderCartBoxCount(count) {
        let $this = this;
        $this.log("renderCartBoxCount");

        document.querySelectorAll(".obj-cart-panel-count").forEach(function(el) {
            el.setAttribute("data-numb", count);
            if(!count) {
                el.classList.remove("itemBtn__icon--numb")
            }
            else {
                el.classList.add("itemBtn__icon--numb");
            }
        })
    }

    getStorage() {
        let cart = this.#cartStorage.getItem(this.#cart_key);
        if (!cart)
            cart = '{}';
        return JSON.parse(cart);
    }

    setStorage(cart) {
        if (!cart)
            cart = {};
        return this.#cartStorage.setItem(this.#cart_key, JSON.stringify(cart));
    }

    setLastUpdated() {
        this.log("setLastUpdated");
        let seconds = new Date().getTime() / 1000;
        this.#cartStorage.setItem(this.#cart_key_last_updated, seconds);
    }

    touchLastUpdated() {
        this.log("touchLastUpdated");
        let seconds = this.#cartStorage.getItem(this.#cart_key_last_updated);
        if (!seconds)
            seconds = 0;
        this.#cartStorage.setItem(this.#cart_key_last_updated, seconds + 1);
    }

    needUpdate() {
        let seconds = new Date().getTime() / 1000;
        let last_updated = this.#cartStorage.getItem(this.#cart_key_last_updated);
        return !last_updated || (seconds - last_updated) > this.cached_date_for_box;
    }

    renderInCart(el) {
        if (!el.classList.contains("in_cart_already")) {
            el.innerText = "В корзине";
            el.classList.add("in_cart_already");
        }
    }

    renderOutCart(el) {
        el.innerText = "В корзину";
        el.classList.remove("in_cart_already");
    }

    markInCart() {
        let $this = this;
        $this.log("markInCart");
        let cart = $this.getStorage();
        if (cart) {
            for (let key in cart) {
                let btns = document.querySelectorAll(".add_to_cart_from_catalog[data-product='" + cart[key]["id"] + "']:not(.in_cart_already)");
                if (btns.length) {
                    btns.forEach(function (btn) {
                        $this.renderInCart(btn);
                    })
                }
            }
        }
    }
}

window.CartApp = new CartClass;
window.CartApp.init();

/*if (document.readyState === 'loading') {
    window.addEventListener('DOMContentLoaded', function () {
        window.CartApp.init();
    });
} else {
    window.CartApp.init();
}*/
