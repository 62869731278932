class GlobaSite {
    #debug = true;

    auth = false;
    page = {
        catalog: "",
        cart: "",
        order_success: "",
    };
    ajax = {
        cart: {
            db_sync: "",
            get: "",
            load: "",
            send_order: "",
        },
        product: {
            get_marketing: "",
            show: ""
        }
    };

    log(msg) {
        if (this.#debug) console.log(msg);
    }

    warn(msg) {
        if (this.#debug) console.error(msg);
    }

    error(msg) {
        if (this.#debug) console.error(msg);
    }

    initMobileMenu() {
        let menu_items
        if ((menu_items = document.querySelectorAll(".headerKatalog__content[data-menu-block='main'] .catalogList__category")) && menu_items.length) {
            menu_items.forEach(function (item) {
                let a = item.querySelector(".headerKatalog__contentNameCat");
                let img = item.querySelector(".catalogList__img img");
                let href = a.href;
                let item_id = a.dataset["menuItem"];
                let title = a.innerText.trim();
                let img_src = img.dataset["imageMob"];

                let el;
                if (item_id) {
                    el = document.createElement("button");
                    el.dataset["mainmenuItem"] = item_id;
                    el.dataset["graphPath"] = "categor";
                } else {
                    el = document.createElement("a");
                    el.href = href;
                }
                let img_el = document.createElement("img");
                img_el.classList.add("modalMain__menuListItemImg");
                img_el.classList.add("lazyload");
                img_el.dataset["src"] = img_src;
                img_el.width = 60;
                img_el.height = 60;

                let title_el = document.createElement("div");
                title_el.classList.add("modalMain__menuListItemText");
                title_el.append(title);

                el.classList.add("modalMain__menuListItem");
                el.classList.add("btn-reset");
                el.classList.add("modal-btn");
                el.append(img_el);
                el.append(title_el);

                document.getElementById("menu_main_mobile_list").append(el);
            });
            if (typeof window.initModalBtn == "function")
                window.initModalBtn();

        }
    }

    initMobileMenuModal(id) {
        let obj = document.getElementById("menu_children_mobile_list");
        let items = document.querySelectorAll("[data-menu-block='" + id + "'] .catalogList__category");

        obj.innerHTML = "";
        if (items.length) {
            items.forEach(function (item) {
                let item_a = item.querySelector(".headerKatalog__contentNameCat");
                let item_img = item.querySelector(".catalogList__img img");

                let name = item_a.innerText.trim();
                let a = document.createElement("a");
                a.href = item_a.href;
                a.classList.add("modalCategor__categor");

                if (item_img.dataset["imageMob"]) {
                    let img = document.createElement("img");
                    img.src = item_img.dataset["imageMob"];
                    img.alt = name;
                    img.classList.add("modalCategor__img");
                    a.append(img);
                }
                let span = document.createElement("div");
                span.classList.add("modalCategor__name");
                span.append(name);

                a.append(span);
                obj.append(a);
            });
        }
    }

    showProduct(id) {
        if (typeof window.MarketingApp != "undefined")
            window.MarketingApp.setShowProductById(id);
        else {
            setTimeout(function () {
                window.App.showProduct(id)
            }, 300);
        }
    }

    /**
     * Создает экземпляр класса и вызывает указанный метод.
     * @param {string} className - Имя класса.
     * @param {string} globalVarName - Имя переменной, в которую будет сохранен экземпляр класса.
     * @param {string} methodName - Имя метода, который нужно вызвать.
     * @param {Object} methodArgs - Объект с аргументами для вызова метода.
     */
    initializeClass(className, globalVarName, methodName, methodArgs) {
        // Создаем экземпляр класса
        window[globalVarName] = new window[className]();

        // Вызываем указанный метод, если он существует
        if (typeof window[globalVarName][methodName] === 'function') {
            window[globalVarName][methodName](methodArgs); // Передаем объект как аргумент
        } else {
            this.error(`Метод ${methodName} не найден в классе ${className}.`);
        }

        this.log(`Класс ${className} успешно инициализирован.`);
    }

    /**
     * Загружает класс, создает его экземпляр и вызывает указанный метод.
     * @param {string} className - Имя класса (должно совпадать с именем файла).
     * @param {string} globalVarName - Имя переменной, в которую будет сохранен экземпляр класса.
     * @param {string} methodName - Имя метода, который нужно вызвать.
     * @param {Object} methodArgs - Объект с аргументами для вызова метода.
     */
    loadClass(className, globalVarName, methodName, methodArgs) {
        const $this = this;
        // Проверяем, существует ли уже экземпляр класса
        if (window[globalVarName]) {
            $this.warn(`Экземпляр класса ${globalVarName} уже существует.`);
            return;
        }

        // Проверяем, загружен ли уже класс
        if (window[className]) {
            $this.log(`Класс ${className} уже загружен. Создаем экземпляр и вызываем метод.`);
            $this.initializeClass(className, globalVarName, methodName, methodArgs);
            return;
        }

        // Создаем элемент <script> для загрузки файла
        const script = document.createElement('script');
        script.src = `/front/js/classes_afterload/${className}.js`;
        script.async = true;
        script.onload = () => {
            // Проверяем, существует ли класс в глобальной области видимости
            if (window[className]) {
                $this.initializeClass(className, globalVarName, methodName, methodArgs);
            } else {
                $this.error(`Класс ${className} не найден в загруженном файле.`);
            }
        };
        script.onerror = () => {
            $this.error(`Ошибка при загрузке файла ${className}.js`);
        };

        // Добавляем <script> в документ для загрузки
        document.head.appendChild(script);
    };
}

window.App = new GlobaSite;
